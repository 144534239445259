import {
  required,
  minLength,
  maxLength,
  requiredIf,
  minValue,
  helpers,
  email,
} from '@vuelidate/validators';

import dayjs from './dayjs';

const fio = helpers.regex(/[а-яА-Я -]{2,}/);
const phone = helpers.regex(/\+7 \(9\d{2}\) \d{3} \d{2}-\d{2}/);
const cyrrylicAndDash = helpers.regex(/[а-яА-Я-]{2,}/);

const pastDate = (nv) => {
  const pastDate = dayjs(nv, 'DD.MM.YYYY', true).format();
  if (pastDate === 'Invalid Date') {
    return false;
  }
  const diff1 = dayjs().diff(pastDate, 'minute');
  if (diff1 < 0) {
    return false;
  }
  const diff2 = dayjs().diff(pastDate, 'year');
  if (!nv || isNaN(diff2) || diff2 >= 100) {
    return false;
  }

  return true;
};

const birthDate = (nv) => {
  const birthDate = dayjs(nv, 'DD.MM.YYYY', true).format();
  if (birthDate === 'Invalid Date') {
    return false;
  }
  const diff = dayjs().diff(birthDate, 'year');
  if (!nv || isNaN(diff) || diff < 18 || diff > 72) {
    return false;
  }
  return true;
};

const PassportDataRules = computed(() => {
  return {
    passport_serie: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(4),
    },
    passport_number: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(6),
    },
    passport_issuedby: {
      required,
    },
    passport_date: {
      required,
      pastDate,
    },
    passport_devcode: {
      required,
      minLength: minLength(7),
      maxLength: maxLength(7),
    },
    birthday: {
      required,
      birthDate,
    },
    birthplace: {
      required,
    },
  };
});

const ResidenceDataRules = (dataForLongStore) =>
  computed(() => {
    return {
      adressKLADR_residence_city_with_type: { required },
      adressKLADR_residence_region_with_type: { required },
      adressKLADR_residence_street_with_type: { required },
      adressKLADR_residence_house: { required },
      adressKLADR_registration_city_with_type: {
        requiredIf: requiredIf(() => !dataForLongStore.step_3.equal_registration),
      },
      adressKLADR_registration_region_with_type: {
        requiredIf: requiredIf(() => !dataForLongStore.step_3.equal_registration),
      },
      adressKLADR_registration_street_with_type: {
        requiredIf: requiredIf(() => !dataForLongStore.step_3.equal_registration),
      },
      adressKLADR_registration_house: {
        requiredIf: requiredIf(() => !dataForLongStore.step_3.equal_registration),
      },
      adressKLADR_temp_registration_city_with_type: {
        requiredIf: requiredIf(
          () =>
            dataForLongStore.step_3.lack_of_registration ||
            dataForLongStore.step_3.equal_temp_registration,
        ),
      },
      adressKLADR_temp_registration_region_with_type: {
        requiredIf: requiredIf(
          () =>
            dataForLongStore.step_3.lack_of_registration ||
            dataForLongStore.step_3.equal_temp_registration,
        ),
      },
      adressKLADR_temp_registration_street_with_type: {
        requiredIf: requiredIf(
          () =>
            dataForLongStore.step_3.lack_of_registration ||
            dataForLongStore.step_3.equal_temp_registration,
        ),
      },
      adressKLADR_temp_registration_house: {
        requiredIf: requiredIf(
          () =>
            dataForLongStore.step_3.lack_of_registration ||
            dataForLongStore.step_3.equal_temp_registration,
        ),
      },
    };
  });
const InformationOnTheWorkRules = (dataForLongStore) =>
  computed(() => {
    return {
      work_mode: { required, minValue: minValue(0) },
      fixed_income: {
        requiredIf: requiredIf(() => Number(dataForLongStore.step_4.work_mode) === 1),
      },
      additional_income_comment: {
        requiredIf: requiredIf(() => +dataForLongStore.step_4.additional_income > 0),
      },
      work_type_guid: {
        requiredIf: requiredIf(() => Number(dataForLongStore.step_4.work_mode) === 1),
      },
      work_position: {
        requiredIf: requiredIf(() => Number(dataForLongStore.step_4.work_mode) === 1),
      },
      avg_monthly_payment: {
        required,
      },
      spouse_full_name: {
        requiredIf: requiredIf(() => Number(dataForLongStore.step_4.marital_status) === 1),
        fio,
      },
      work_company: {
        requiredIf: requiredIf(() => Number(dataForLongStore.step_4.work_mode) === 1),
      },
      work_company_phone: {
        requiredIf: requiredIf(() => Number(dataForLongStore.step_4.work_mode) === 1),
      },
      adressKLADR_work_city_with_type: {
        requiredIf: requiredIf(() => Number(dataForLongStore.step_4.work_mode) === 1),
      },
      adressKLADR_work_region_with_type: {
        requiredIf: requiredIf(() => Number(dataForLongStore.step_4.work_mode) === 1),
      },
      adressKLADR_work_street_with_type: {
        requiredIf: requiredIf(() => Number(dataForLongStore.step_4.work_mode) === 1),
      },
      adressKLADR_work_house: {
        requiredIf: requiredIf(() => Number(dataForLongStore.step_4.work_mode) === 1),
      },
      contact_persons_1_full_name: { required, fio },
      contact_persons_1_kind_text: { required, fio },
      contact_persons_1_mobile_phone: { required, phone },
    };
  });

const GeneralInformationRules = computed(() => {
  return {
    first_name: {
      required,
      cyrrylicAndDash,
    },
    last_name: {
      required,
      cyrrylicAndDash,
    },
    city: {
      required,
    },
    secondary_name: {
      cyrrylicAndDash,
    },
    email: {
      email,
    },
    mobile_phone: {
      required,
      phone,
    },
    cityKLADR_residence_kladr_id: {
      required,
      minLength: minLength(10),
    },
  };
});

const AppealRules = computed(() => {
  return {
    fullName: { required, cyrrylicAndDash },
    phone: { required, phone },
    email: { required, email },
    message: { required },
  };
});

const payContractRules = computed(() => {
  return {
    payment_summ: {
      required,
    },
    name: {
      required,
      fio,
    },
    phone: {
      required,
      phone,
    },
    contractEmail: {
      email,
    },
    birth_date: {
      required,
      birthDate,
    },
  };
});
const findContractRules = computed(() => {
  return {
    contractNumber: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(10),
    },
    birth_date: {
      required,
      birthDate,
    },
  };
});

const callOrderRules = computed(() => {
  return {
    name: {
      required,
      minLength: minLength(1),
    },
    phone: {
      required,
      phone,
    },
  };
});

export {
  PassportDataRules,
  ResidenceDataRules,
  InformationOnTheWorkRules,
  GeneralInformationRules,
  AppealRules,
  findContractRules,
  payContractRules,
  callOrderRules,
};
