<template>
  <label
    ref="inputContainer"
    class="input-wrapper relative flex h-[64px] items-center overflow-hidden rounded-lg border-2 pl-5 lg:min-h-[88px] lg:rounded-[20px] lg:pl-6"
    :class="{
      '!border-ui_red !bg-ui_red_secondary': props.error,
    }"
  >
    <input
      ref="input"
      v-maska
      :placeholder="placeholder"
      v-bind="{
        'data-maska': $attrs['data-maska'],
        'data-maska-tokens': $attrs['data-maska-tokens'],
      }"
      class="w-full bg-transparent pt-4 text-sm outline-none focus:outline-none lg:text-lg"
      :class="{
        'cursor-default bg-ui_white': fakeDisabled,
      }"
      autocomplete="one-time-code"
      :value="modelValue"
      :type="inputType !== 'date' ? inputType : 'text'"
      :inputmode="inputmode"
      :disabled="disabled || fakeDisabled"
      @input="inputHandler"
      @focus="focusHandler"
      @blur="blurHandler"
    />

    <span
      class="title customTransition absolute left-5 origin-top-left cursor-text touch-none select-none font-manrope font-semibold leading-none text-ui_dark_grey lg:left-6"
      :class="{
        '!cursor-not-allowed': disabled,
      }"
    >
      {{ title }}
    </span>

    <div class="h-full pr-5 lg:pr-6">
      <div
        v-if="type === 'password'"
        class="-mr-6 flex h-full cursor-pointer justify-center pl-3 pr-6"
        @click="isPasswordVisible = !isPasswordVisible"
      >
        <Icon
          :key="passwordIconFill"
          name="eye"
          size="sm"
          class="cursor-pointer"
          :fill="passwordIconFill"
        />
      </div>

      <div
        v-if="loading"
        class="-mr-6 flex h-full pl-3 pr-6"
      >
        <IsLoading size="md" />
      </div>

      <div
        v-if="promotion"
        class="-mr-6 flex h-full items-center pl-3 pr-6"
      >
        <div
          class="flex touch-none select-none rounded-lg bg-ui_green p-2 text-xxs text-ui_white transition-all lg:text-xs"
        >
          +{{ promotionPercent }}%
          <div
            v-if="promotionText"
            class="whitespace-nowrap transition-all"
            :class="{ 'text-[0px]': isInputFocused || modelValue }"
          >
            &nbsp;{{ promotionText }}
          </div>
        </div>
      </div>

      <div
        v-if="$slots.button && !loading"
        class="flex h-full touch-none select-none items-center pl-3"
      >
        <slot name="button" />
      </div>

      <div
        v-if="type === 'date'"
        class="-mx-6 flex h-full cursor-pointer touch-none select-none items-center pl-3 pr-6"
        @click="toggleDatePickerModal"
      >
        <Icon
          name="date"
          size="sm"
          fill="#8C8C8C"
        />
      </div>
    </div>

    <Modal
      v-if="type === 'date'"
      title="Выберите дату"
      :open="isDatePickerModalVisible"
      @close="toggleDatePickerModal"
      custom-class="max-w-[400px]"
    >
      <template #default>
        <div ref="datePicker">
          <transition name="fade">
            <Calendar
              class="w-full"
              v-model="date"
              @close="
                () => {
                  closeDatePicker();
                }
              "
            />
          </transition>
        </div>
      </template>
    </Modal>
  </label>
</template>

<script setup>
import Icon from '~/components/Ui/Icon.vue';
import IsLoading from '~/components/Ui/IsLoading.vue';
import Modal from '~/components/Ui/Modal.vue';
import Calendar from '~/components/Ui/Calendar.vue';

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: ' ',
  },
  type: {
    type: String,
    default: 'text',
  },
  inputmode: {
    type: String,
    default: 'text',
  },
  error: Boolean,
  promotion: Boolean,
  promotionPercent: {
    type: String,
    default: '10',
  },
  promotionText: {
    type: String,
    default: 'к одобрению',
  },
  loading: Boolean,
  disabled: {
    type: Boolean,
    default: false,
  },
  fakeDisabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['input', 'update:modelValue']);

const input = ref(null);
const inputContainer = ref(null);
const blur = ref(false);
const date = ref('');
const isInputFocused = ref(false);
const isPasswordVisible = ref(false);
const isDatePickerModalVisible = ref(false);

const inputType = computed(() => {
  if (props.type === 'password') {
    return isPasswordVisible.value ? 'text' : 'password';
  }

  return props.type;
});

const passwordIconFill = computed(() => {
  return isPasswordVisible.value ? '#8C8C8C' : '#1F1F1F';
});

const inputHandler = (e) => {
  emit('update:modelValue', e.target.value);
  emit('input', e);
};

const focusHandler = () => {
  if (isDatePickerModalVisible.value) {
    isInputFocused.value = false;
    blur.value = true;
    input.value.blur();
  } else {
    isInputFocused.value = true;
  }
};

const blurHandler = () => {
  isInputFocused.value = false;
  blur.value = true;
};

const toggleDatePickerModal = () => {
  isDatePickerModalVisible.value = !isDatePickerModalVisible.value;
};

const closeDatePicker = () => {
  isDatePickerModalVisible.value = false;
  isInputFocused.value = false;
  blur.value = true;
};

const checkClickInDatePicker = (event) => {
  if (isInputFocused) {
    if (!inputContainer?.value?.contains(event.target)) {
      blur.value = true;
      input.value?.blur(event);
      isInputFocused.value = false;
    }
  }
};

watch(
  () => date.value,
  (nv) => {
    emit('update:modelValue', nv);
    input.value.blur();
  },
);

onMounted(() => {
  if (inputType.value === 'date')
    document.addEventListener('click', (event) => {
      checkClickInDatePicker(event);
    });
});

onBeforeUnmount(() => {
  if (inputType.value === 'date')
    document.removeEventListener('click', (event) => {
      checkClickInDatePicker(event);
    });
});
</script>

<style scoped>
.customTransition {
  transition-property: transform, font-size;
  transition-timing-function: ease;
  transition-duration: 150ms;
}

input {
  -webkit-text-fill-color: #1f1f1f;
  opacity: 1;
}

input::placeholder {
  -webkit-text-fill-color: transparent;
}

input:focus::placeholder {
  -webkit-text-fill-color: #b8b8b8;
}

.input-wrapper {
  @apply border-dnd_light_grey bg-ui_white;
}

.input-wrapper:focus-within {
  @apply border-ui_yellow bg-ui_white;
}

.input-wrapper input:placeholder-shown + .title,
.input-wrapper input:not(:focus) + .title {
  @apply text-base lg:text-lg;
}

.input-wrapper input:not(:placeholder-shown) + .title,
.input-wrapper input:focus + .title,
input:-webkit-autofill + .title {
  @apply -translate-y-[12px] text-xs lg:-translate-y-[13px] lg:text-sm;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
}
</style>
