<template>
  <div
    class="calendar relative z-[150] flex h-[489px] w-[320px] flex-col justify-between rounded-3xl border-2 border-ui_light_grey bg-white p-4"
    :class="{ 'open-upwards': openUpwards }"
  >
    <!-- <div
      class="absolute right-2 transform cursor-pointer rounded-full bg-ui_light_grey p-3 transition-all hover:scale-105"
      @click.stop.prevent="emit('close')"
    >
      <Icon
        name="close"
        size="xs"
      />
    </div> -->
    <div class="mt-4 flex w-full gap-2">
      <Select
        v-model="month"
        class="w-full"
        :options="monthsList"
        value-key="name"
        continuous
        without-selected
        @chosen="changeMonth"
      />
      <Select
        v-model="year"
        class="w-full"
        :options="yearsList"
        value-key="name"
        continuous
        scroll-to
        @chosen="changeYear"
      />
    </div>

    <div class="grid grid-cols-7 gap-y-2">
      <div class="col-start-1 col-end-2 flex justify-center">пн</div>
      <div class="col-start-2 col-end-3 flex justify-center">вт</div>
      <div class="col-start-3 col-end-4 flex justify-center">ср</div>
      <div class="col-start-4 col-end-5 flex justify-center">чт</div>
      <div class="col-start-5 col-end-6 flex justify-center">пт</div>
      <div class="col-start-6 col-end-7 flex justify-center">сб</div>
      <div class="col-start-7 col-end-8 flex justify-center">вс</div>

      <template
        v-for="day in days"
        :key="day"
      >
        <div
          class="flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full text-center text-[14px] font-semibold transition-all hover:bg-ui_light_grey"
          :class="{
            'bg-blue-200': isToday(day),
            'text-gray-400': isNotCurrentMonth(day),
            'bg-ui_yellow hover:bg-ui_yellow': isSelectedDay(day),
            [`col-start-${calculateStartDay(day)}`]: true,
            [`col-end-${calculateEndDay(day)}`]: true,
          }"
          @click.stop.prevent="selectDate(day)"
        >
          {{ day?.getDate() }}
        </div>
      </template>
    </div>
    <div class="flex w-full">
      <Button
        class="!h-[61px] !rounded-2xl"
        type="primary"
        :disabled="!selectedDate"
        @click="submit"
      >
        Ок
      </Button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeMount } from 'vue';
import Select from '~/components/Ui/Select.vue';
import Icon from '~/components/Ui//Icon.vue';
import Button from '~/components/Ui/Button.vue';

const emit = defineEmits(['update:modelValue', 'close']);

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: '',
  },
});

const currentDate = ref(new Date());
const selectedDate = ref('');
const showDatePicker = ref(false);
const openUpwards = ref(false);

const month = ref('');
const year = ref('');
const optionsToLocalString = { day: '2-digit', month: '2-digit', year: 'numeric' };

onBeforeMount(() => {
  initialValue();
});

const initialValue = () => {
  let initialDate;

  if (props.modelValue) {
    const { dayNum, month, year } = getFullDate(props.modelValue);
    initialDate = new Date(year, month - 1, dayNum);
  } else {
    initialDate = new Date();
  }

  selectedDate.value = props.modelValue || '';

  const currentMonth = initialDate.getMonth();
  const currentYear = initialDate.getFullYear();
  const date = new Date(currentYear, currentMonth, 1);
  const monthName = date.toLocaleString('ru-RU', { month: 'long' }, optionsToLocalString);
  currentDate.value = new Date(date);

  month.value = { name: transformMonthName(monthName), value: date, key: currentMonth + 1 };
  year.value = {
    name: currentYear,
    value: currentYear,
    key: currentYear,
  };
};

const days = computed(() => {
  const daysArray = [];
  const currentMonth = currentDate.value.getMonth();
  const lastDay = new Date(currentDate.value.getFullYear(), currentMonth + 1, 0);
  for (let i = 1; i <= lastDay.getDate(); i++) {
    daysArray.push(new Date(currentDate.value.getFullYear(), currentMonth, i));
  }

  return daysArray;
});

const getFullDate = (date) => {
  const [dayNum, month, year] = date.split('.').map(Number);

  return { dayNum, month, year };
};

const yearsList = computed(() => {
  const currentYear = new Date().getFullYear();
  const startYear = currentYear;
  const endYear = currentYear - 60;

  const yearsArray = Array.from({ length: startYear - endYear + 1 }, (_, index) => {
    return { name: startYear - index, value: index, key: startYear - index };
  });

  return yearsArray;
});

const transformMonthName = (name) => {
  const capitalizedMonth = name.charAt(0).toUpperCase() + name.slice(1);
  return capitalizedMonth;
};

const monthsList = computed(() => {
  const currentMonth = 0;
  const currentYear = new Date().getFullYear();
  const months = Array.from({ length: 12 }, (_, index) => {
    const monthIndex = (currentMonth + index) % 12;
    const date = new Date(currentYear, monthIndex, 1);
    const ruDate = new Date(currentYear, monthIndex, 1).setMonth(date.getMonth());

    const nameMonth = date.toLocaleString('ru-RU', { month: 'long' });
    const capitalizedMonth = transformMonthName(nameMonth);

    return {
      name: capitalizedMonth,
      value: new Date(ruDate),
      key: new Date(ruDate).getMonth() + 1,
    };
  });
  return months;
});

const isToday = (date) => {
  const today = new Date();
  return date && date.toDateString() === today.toDateString();
};
const calculateStartDay = (date) => {
  const dayOfWeek = date.getDay();
  return dayOfWeek === 0 ? 7 : dayOfWeek;
};

const calculateEndDay = (date) => {
  const dayOfWeek = date.getDay();
  return dayOfWeek === 6 ? 7 : dayOfWeek + 1;
};

const isSelectedDay = (day) => {
  const { dayNum, month, year } = getFullDate(selectedDate.value);
  const selDate = new Date(year, month - 1, dayNum);
  const calDate = new Date(day);
  return selDate.getTime() === calDate.getTime();
};

const isNotCurrentMonth = (date) => {
  return date && date.getMonth() !== currentDate.value.getMonth();
};

const selectDate = (date) => {
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  selectedDate.value = date.toLocaleDateString('ru-RU', options).split('.').join('.');
  showDatePicker.value = false;
};

const submit = () => {
  emit('update:modelValue', selectedDate.value);
  emit('close');
};

const changeMonth = (nv) => {
  const month = new Date(nv.value).getMonth();
  currentDate.value = new Date(currentDate.value.setMonth(month));
};

const changeYear = (nv) => {
  currentDate.value = new Date(currentDate.value.setFullYear(nv.name));
};

const checkWindowPosition = () => {
  const calendarElement = document.querySelector('.calendar'); // Используйте селектор, который точно определяет ваш календарь
  if (calendarElement) {
    const bounding = calendarElement.getBoundingClientRect();
    const documentHeight = document.body.getBoundingClientRect().height;
    const spaceBelow = documentHeight - bounding.bottom;
    const spaceUnder = documentHeight - bounding.top;

    openUpwards.value = spaceBelow < 300 && spaceUnder < 1200;
  }
};

onMounted(() => {
  checkWindowPosition();
});
</script>

<style scoped>
.open-upwards {
  transform: translateY(-100%);
}
</style>
