<template>
  <section
    v-if="open"
    ref="modal"
    tabindex="0"
    class="customModal fixed left-0 top-0 flex h-screen w-screen items-center justify-center overflow-hidden transition-all"
    :style="{
      'z-index': zIndex,
    }"
    @click.self="closeModal"
    @keydown.esc="closeModal"
  >
    <Transition name="scale-in">
      <div
        v-show="animationVisible"
        class="relative max-h-[100vh] max-w-[90vw] overflow-hidden rounded-[24px] bg-white p-6 transition-all"
        :class="{
          [customClass]: true,
          [width]: !customClass,
        }"
      >
        <div
          class="absolute right-4 top-4 h-fit w-fit cursor-pointer rounded-full bg-ui_light_grey p-[10px] transition-all hover:bg-ui_mid_grey"
          @click="closeModal"
        >
          <Icon
            fill="black"
            name="close"
            size="xs"
          />
        </div>
        <div class="w-full pt-5 text-center">
          <p class="text-wrap text-xl font-bold lg:text-pretty lg:text-2xl">
            {{ title }}
          </p>
        </div>
        <div
          class="mt-3 flex flex-col gap-y-3"
          :class="{
            'max-h-[75vh] overflow-y-auto': scrollable,
          }"
        >
          <slot></slot>
        </div>
        <div class="mt-3 flex w-full justify-center lg:mt-6">
          <slot name="button"></slot>
        </div>
      </div>
    </Transition>
  </section>
</template>

<script setup>
import Icon from '~/components/Ui/Icon.vue';
const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  customClass: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: 'md',
  },
  title: {
    type: String,
    default: '',
  },
  zIndex: {
    type: String,
    default: '900',
  },
  scrollable: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close']);

const modal = ref(null);
const animationVisible = ref(props.open);

const sizes = {
  xs: 'md:w-1/4',
  sm: 'md:w-5/12',
  md: 'lg:w-[600px]',
  lg: 'md:w-2/3',
  xl: 'md:w-5/6',
  min: 'md:w-min lg:h-min',
  fit: 'md:w-[fit-content]',
};

const width = computed(() => sizes[props.size]);

const disableBodyScroll = () => {
  document.body.style.overflow = 'hidden';
};

const enableBodyScroll = () => {
  document.body.style.overflow = '';
};

const closeModal = () => {
  if (props.open) {
    animationVisible.value = false;
    setTimeout(() => {
      emit('close');
    }, 200);
    enableBodyScroll();
  }
};

onUnmounted(() => {
  closeModal();
});

watch(
  () => props.open,
  async () => {
    if (props.open === true) {
      await nextTick();
      animationVisible.value = true;
      modal.value.focus();
      disableBodyScroll();
    } else {
      closeModal();
    }
  },
);
</script>

<style scoped>
.customModal {
  -webkit-overflow-scrolling: touch;
  background-color: rgba(34, 39, 44, 0.8);
}

/* width */
::-webkit-scrollbar {
  width: 16px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgray;
  background-clip: padding-box;
  border: 4px solid transparent;
  border-radius: 9999px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  background-clip: padding-box;
}

.scale-in-enter-from,
.scale-in-leave-to {
  transform: scale(0.2);
  opacity: 0;
}

.scale-in-enter-active,
.scale-in-leave-active {
  transition:
    transform 0.2s,
    opacity 0.2s;
}
</style>
