<template>
  <label class="relative">
    <div
      ref="select"
      class="animate-width select-input transition_bg h-auto cursor-pointer rounded-lg border-2 border-dnd_light_grey outline-none lg:rounded-[20px]"
      :class="{
        continuous: continuous,
        small: small,
        '!h-[38px] border-ui_light_grey': small,
        'border-ui_red bg-ui_red_secondary': small,
        'bg-white': !continuous && !error,
        'border-ui_red bg-ui_red_secondary': error && !visibilitySelect,
        'border-ui_yellow': visibilitySelect && !continuous,
        'relative z-20 border-ui_light_grey !bg-ui_light_grey': continuous && visibilitySelect,
      }"
      tabindex="0"
      @keydown.down="pressDown"
      @keydown.up="pressUp"
      @keyup.enter="handleEnter"
      @keyup.esc="closeDropdown"
      @click="onClick"
    >
      <div
        class="absolute flex h-full transform"
        :class="{
          [placeholder ? 'right-6' : 'right-4']: true,
          'z-30': continuous,
          '-right-0 top-0 h-6 w-6 translate-x-2': small,
          'h-6 w-6': !small,
        }"
      >
        <Suspense>
          <Icon
            name="arrow"
            :size="!continuous ? (small ? 'xs' : 'sm') : 'xs'"
            :stroke="[error && !visibilitySelect ? '#EE2F53' : '#F7E017']"
            class="transform transition-all"
            :class="{ 'rotate-180': visibilitySelect }"
          />
        </Suspense>
      </div>
      <div class="current-item font-manrope">
        {{ currentItem[valueKey] }}
      </div>
      <span
        v-if="placeholder && (!small || !currentItem[valueKey])"
        class="placeholder absolute touch-none select-none font-manrope transition-all"
        :class="{
          'left-5 text-ui_dark_grey lg:left-6': !small,
          'left-5 top-1/2 -translate-y-1/2 transform': small,
          'font-manrope !text-xs font-normal text-black': small && !isScreenSizeBig,
          '!text-base': small && isScreenSizeBig,
        }"
      >
        {{ placeholder }}
      </span>
    </div>
    <transition name="select">
      <div
        v-if="visibilitySelect"
        ref="suggestions"
        class="absolute left-0 z-[1000] mt-2 max-h-[160px] w-full overflow-y-auto rounded-2.5xl border-2 border-ui_light_grey outline-none lg:max-h-[212px]"
        :class="{
          'top-7 mt-0 max-h-[300px] rounded-t-none bg-ui_light_grey': continuous,

          'bg-white': !continuous,
        }"
      >
        <ul
          class="flex flex-col gap-3 lg:gap-4"
          :class="{
            [continuous ? 'p-4 pl-3.5' : 'py-3 pl-5 lg:py-6 lg:pl-6']: true,
          }"
        >
          <li
            v-for="(item, index) in options"
            :id="item[valueKey]"
            :key="item[valueKey]"
            class="option element cursor-pointer font-manrope transition-all hover:text-ui_yellow"
            :class="{
              continuous: continuous,
              small: small,
              'text-ui_yellow': focusedItem === index,
              'font-bold': currentItem[valueKey] === item[valueKey],
            }"
            @mousedown.stop="clickHandler(item)"
          >
            {{ item[nameKey] }}
          </li>
        </ul>
      </div>
    </transition>
  </label>
</template>

<script setup>
import Icon from '~/components/Ui/Icon.vue';

const emit = defineEmits(['update:modelValue', 'chosen']);

const props = defineProps({
  modelValue: {
    type: [Array, Object, Number, String],
    required: true,
  },
  placeholder: {
    type: String,
    default: '',
  },
  options: {
    type: Array,
    required: true,
  },
  valueKey: {
    type: String,
    default: 'value',
  },
  nameKey: {
    type: String,
    default: 'name',
  },
  error: {
    type: Boolean,
    default: false,
  },
  continuous: {
    type: Boolean,
    default: false,
  },
  scrollTo: {
    type: Boolean,
    default: false,
  },
  returnKey: {
    type: String,
    default: 'object',
  },
  small: {
    type: Boolean,
    default: false,
  },
  isScreenSizeBig: {
    type: Boolean,
    default: false,
  },
});

const visibilitySelect = ref(false);
const focusedItem = ref(-1);
const select = ref(null);
const suggestions = ref(null);
const selectInput = ref(null);

const currentItem = computed(() => {
  return (
    props.options.find((el) => {
      return el?.[props.valueKey] === props.modelValue?.[props.valueKey];
    }) || {}
  );
});

const closeDropdown = () => {
  if (visibilitySelect.value) {
    visibilitySelect.value = false;
    focusedItem.value = -1;
  }
};

const onClick = () => {
  visibilitySelect.value = !visibilitySelect.value;
};

const scroll = () => {
  try {
    suggestions.value.scrollTop =
      suggestions.value.children[0].children[focusedItem.value].offsetTop - 40;
  } catch (err) {}
};

const pressDown = () => {
  if (
    props.options.length &&
    visibilitySelect.value &&
    focusedItem.value > -2 &&
    focusedItem.value < props.options.length - 1
  ) {
    focusedItem.value = focusedItem.value + 1;
    scroll();
  }
};

const pressUp = () => {
  if (
    visibilitySelect.value &&
    props.options.length &&
    focusedItem.value > -2 &&
    focusedItem.value > 0
  ) {
    focusedItem.value = focusedItem.value - 1;
    scroll();
  }
};

const clickHandler = (el) => {
  if (el && props.nameKey in el) {
    if (props.returnKey !== 'object') {
      emit('chosen', el[props.returnKey]);
    } else {
      emit('chosen', el);
    }
    emit('update:modelValue', el);
    visibilitySelect.value = false;
  }
};

const handleEnter = () => {
  if (!visibilitySelect.value) {
    visibilitySelect.value = true;
    return;
  }
  if (props.options.length) {
    clickHandler(props.options[focusedItem.value]);
  }
};

const scrollTo = () => {
  setTimeout(() => {
    const optionToScrollTo = document.getElementById(props.modelValue[props.valueKey]);
    if (suggestions.value) {
      const optionPosition =
        optionToScrollTo.offsetTop - suggestions.value.offsetTop + optionToScrollTo.clientHeight;
      suggestions.value.scrollTop = optionPosition;
    }
  });
};

watch(visibilitySelect, (nv) => {
  if (!nv) focusedItem.value = -1;
  if (props.scrollTo) scrollTo();
});
</script>

<style scoped>
.select-enter-from,
.select-leave-to {
  opacity: 0;
}

.select-enter-active,
.select-leave-active {
  transition: all 0.1s;
}

.transition_bg {
  transition: all 0.1s;
}

.animate-width {
  transition: width 0.3s ease;
}

.option {
  @apply text-base lg:text-lg;
}

.option.continuous {
  @apply text-sm;
}

.option.small {
  @apply text-xs lg:text-base;
}

.select-input {
  @apply min-h-16 text-base lg:min-h-[88px] lg:text-lg;
}

.select-input.continuous {
  @apply flex !h-[52px] min-h-0 items-center;
}

.select-input.small {
  @apply min-h-[36px] text-xs font-bold lg:min-h-[48px] lg:text-base;
}

.select-input .current-item {
  @apply pl-5 pt-7 lg:pl-6 lg:pt-[38px];
}

.select-input.small .current-item {
  @apply pl-5 pt-2 lg:pl-6 lg:pt-2.5;
}

.select-input.continuous .current-item {
  @apply pl-3.5 pt-0 text-sm;
}
.select-input div:not(:empty) + .placeholder {
  @apply top-3 translate-x-0.5 transform text-xs lg:top-5 lg:text-sm;
}

.select-input div:empty + .placeholder {
  @apply top-4.5 text-base font-semibold lg:top-[30px] lg:!text-lg;
}
</style>
